import Inject from '@/modules/shared/domain/di/inject';
import TYPES from '@/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class ModalPrepareDocumentsWealthViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace =
    'components.flagship.flagship-link-goals.modal-prepare-documents-wealth';

  contracts_saving_modal = {
    is_open: false,
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  startOnBoardingAllianz = () => {
    this.contracts_saving_modal.is_open = true;
  };
}
